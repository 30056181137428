const config = {
    title: 'Jégvarázs',
    videoId: 'ZtYB-e5BJ_0',
    start: 20,
    end: 90,
    arrows: [
        {
            "startTime": 22230,
            "type": "arrowUp"
        },
        {
            "startTime": 23285,
            "type": "arrowRight"
        },
        {
            "startTime": 25116,
            "type": "arrowDown"
        },
        {
            "startTime": 27071,
            "type": "arrowLeft"
        },
        {
            "startTime": 29253,
            "type": "arrowUp"
        },
        {
            "startTime": 30851,
            "type": "arrowRight"
        },
        {
            "startTime": 32573,
            "type": "arrowLeft"
        },
        {
            "startTime": 33966,
            "type": "arrowDown"
        },
        {
            "startTime": 36536,
            "type": "arrowUp"
        },
        {
            "startTime": 37726,
            "type": "arrowDown"
        },
        {
            "startTime": 39228,
            "type": "arrowUp"
        },
        {
            "startTime": 40813,
            "type": "arrowDown"
        },
        {
            "startTime": 42083,
            "type": "arrowDown"
        },
        {
            "startTime": 43925,
            "type": "arrowRight"
        },
        {
            "startTime": 43940,
            "type": "arrowLeft"
        },
        {
            "startTime": 45950,
            "type": "arrowUp"
        },
        {
            "startTime": 47005,
            "type": "arrowRight"
        },
        {
            "startTime": 48030,
            "type": "arrowUp"
        },
        {
            "startTime": 49524,
            "type": "arrowLeft"
        },
        {
            "startTime": 51437,
            "type": "arrowDown"
        },
        {
            "startTime": 52630,
            "type": "arrowUp"
        },
        {
            "startTime": 53629,
            "type": "arrowDown"
        },
        {
            "startTime": 53637,
            "type": "arrowUp"
        },
        {
            "startTime": 57176,
            "type": "arrowRight"
        },
        {
            "startTime": 57177,
            "type": "arrowLeft"
        },
        {
            "startTime": 59305,
            "type": "arrowUp"
        },
        {
            "startTime": 60101,
            "type": "arrowRight"
        },
        {
            "startTime": 60789,
            "type": "arrowUp"
        },
        {
            "startTime": 61717,
            "type": "arrowUp"
        },
        {
            "startTime": 62484,
            "type": "arrowLeft"
        },
        {
            "startTime": 63389,
            "type": "arrowUp"
        },
        {
            "startTime": 64229,
            "type": "arrowDown"
        },
        {
            "startTime": 66919,
            "type": "arrowRight"
        },
        {
            "startTime": 68900,
            "type": "arrowLeft"
        },
        {
            "startTime": 71782,
            "type": "arrowDown"
        },
        {
            "startTime": 71845,
            "type": "arrowRight"
        },
        {
            "startTime": 71846,
            "type": "arrowLeft"
        },
        {
            "startTime": 74535,
            "type": "arrowUp"
        },
        {
            "startTime": 74540,
            "type": "arrowDown"
        },
        {
            "startTime": 77338,
            "type": "arrowRight"
        },
        {
            "startTime": 78624,
            "type": "arrowLeft"
        },
        {
            "startTime": 80220,
            "type": "arrowRight"
        },
        {
            "startTime": 82064,
            "type": "arrowLeft"
        },
        {
            "startTime": 85142,
            "type": "arrowDown"
        },
        {
            "startTime": 86029,
            "type": "arrowUp"
        },
        {
            "startTime": 86726,
            "type": "arrowDown"
        },
        {
            "startTime": 88156,
            "type": "arrowRight"
        },
        {
            "startTime": 88741,
            "type": "arrowLeft"
        }
    ]
};

export default config;