const config = {
    title: 'Gryllus Vilmos: Maszkabál - Tigris',
    videoId: 'zYC20Z0XwNg',
    start: 0,
    end: 60,
    arrows: [
        {
            "startTime": 2573,
            "type": "arrowLeft"
          },
          {
            "startTime": 2998,
            "type": "arrowLeft"
          },
          {
            "startTime": 3013,
            "type": "arrowLeft"
          },
          {
            "startTime": 3077,
            "type": "arrowLeft"
          },
          {
            "startTime": 3798,
            "type": "arrowRight"
          },
          {
            "startTime": 4055,
            "type": "arrowRight"
          },
          {
            "startTime": 4119,
            "type": "arrowDown"
          },
          {
            "startTime": 4205,
            "type": "arrowRight"
          },
          {
            "startTime": 4237,
            "type": "arrowRight"
          },
          {
            "startTime": 4621,
            "type": "arrowRight"
          },
          {
            "startTime": 4637,
            "type": "arrowLeft"
          },
          {
            "startTime": 4935,
            "type": "arrowLeft"
          },
          {
            "startTime": 4957,
            "type": "arrowRight"
          },
          {
            "startTime": 5357,
            "type": "arrowUp"
          },
          {
            "startTime": 5361,
            "type": "arrowDown"
          },
          {
            "startTime": 6596,
            "type": "arrowRight"
          },
          {
            "startTime": 6613,
            "type": "arrowUp"
          },
          {
            "startTime": 6942,
            "type": "arrowRight"
          },
          {
            "startTime": 7301,
            "type": "arrowUp"
          },
          {
            "startTime": 8301,
            "type": "arrowUp"
          },
          {
            "startTime": 8598,
            "type": "arrowRight"
          },
          {
            "startTime": 9198,
            "type": "arrowRight"
          },
          {
            "startTime": 9240,
            "type": "arrowLeft"
          },
          {
            "startTime": 9862,
            "type": "arrowUp"
          },
          {
            "startTime": 10157,
            "type": "arrowRight"
          },
          {
            "startTime": 10520,
            "type": "arrowRight"
          },
          {
            "startTime": 11645,
            "type": "arrowRight"
          },
          {
            "startTime": 11998,
            "type": "arrowUp"
          },
          {
            "startTime": 12182,
            "type": "arrowRight"
          },
          {
            "startTime": 12772,
            "type": "arrowRight"
          },
          {
            "startTime": 12844,
            "type": "arrowLeft"
          },
          {
            "startTime": 12939,
            "type": "arrowLeft"
          },
          {
            "startTime": 13125,
            "type": "arrowRight"
          },
          {
            "startTime": 14639,
            "type": "arrowRight"
          },
          {
            "startTime": 14653,
            "type": "arrowUp"
          },
          {
            "startTime": 14949,
            "type": "arrowRight"
          },
          {
            "startTime": 15607,
            "type": "arrowUp"
          },
          {
            "startTime": 15957,
            "type": "arrowRight"
          },
          {
            "startTime": 16272,
            "type": "arrowUp"
          },
          {
            "startTime": 16541,
            "type": "arrowUp"
          },
          {
            "startTime": 16573,
            "type": "arrowRight"
          },
          {
            "startTime": 16918,
            "type": "arrowRight"
          },
          {
            "startTime": 16921,
            "type": "arrowUp"
          },
          {
            "startTime": 17182,
            "type": "arrowRight"
          },
          {
            "startTime": 17486,
            "type": "arrowRight"
          },
          {
            "startTime": 18077,
            "type": "arrowUp"
          },
          {
            "startTime": 18422,
            "type": "arrowRight"
          },
          {
            "startTime": 19045,
            "type": "arrowUp"
          },
          {
            "startTime": 19077,
            "type": "arrowRight"
          },
          {
            "startTime": 19711,
            "type": "arrowRight"
          },
          {
            "startTime": 20389,
            "type": "arrowRight"
          },
          {
            "startTime": 21237,
            "type": "arrowRight"
          },
          {
            "startTime": 21252,
            "type": "arrowUp"
          },
          {
            "startTime": 21437,
            "type": "arrowRight"
          },
          {
            "startTime": 21445,
            "type": "arrowUp"
          },
          {
            "startTime": 21652,
            "type": "arrowRight"
          },
          {
            "startTime": 21685,
            "type": "arrowUp"
          },
          {
            "startTime": 21861,
            "type": "arrowRight"
          },
          {
            "startTime": 22134,
            "type": "arrowRight"
          },
          {
            "startTime": 22159,
            "type": "arrowUp"
          },
          {
            "startTime": 22349,
            "type": "arrowRight"
          },
          {
            "startTime": 22381,
            "type": "arrowUp"
          },
          {
            "startTime": 22612,
            "type": "arrowRight"
          },
          {
            "startTime": 22622,
            "type": "arrowUp"
          },
          {
            "startTime": 22877,
            "type": "arrowRight"
          },
          {
            "startTime": 22893,
            "type": "arrowUp"
          },
          {
            "startTime": 23126,
            "type": "arrowRight"
          },
          {
            "startTime": 23340,
            "type": "arrowRight"
          },
          {
            "startTime": 23404,
            "type": "arrowUp"
          },
          {
            "startTime": 23541,
            "type": "arrowRight"
          },
          {
            "startTime": 23566,
            "type": "arrowUp"
          },
          {
            "startTime": 23814,
            "type": "arrowRight"
          },
          {
            "startTime": 23855,
            "type": "arrowUp"
          },
          {
            "startTime": 24046,
            "type": "arrowRight"
          },
          {
            "startTime": 24326,
            "type": "arrowRight"
          },
          {
            "startTime": 24341,
            "type": "arrowUp"
          },
          {
            "startTime": 24574,
            "type": "arrowRight"
          },
          {
            "startTime": 24861,
            "type": "arrowRight"
          },
          {
            "startTime": 24870,
            "type": "arrowUp"
          },
          {
            "startTime": 25134,
            "type": "arrowRight"
          },
          {
            "startTime": 25142,
            "type": "arrowUp"
          },
          {
            "startTime": 25390,
            "type": "arrowRight"
          },
          {
            "startTime": 25415,
            "type": "arrowUp"
          },
          {
            "startTime": 25670,
            "type": "arrowRight"
          },
          {
            "startTime": 25966,
            "type": "arrowRight"
          },
          {
            "startTime": 26246,
            "type": "arrowRight"
          },
          {
            "startTime": 26550,
            "type": "arrowRight"
          },
          {
            "startTime": 26870,
            "type": "arrowRight"
          },
          {
            "startTime": 26874,
            "type": "arrowUp"
          },
          {
            "startTime": 27134,
            "type": "arrowRight"
          },
          {
            "startTime": 27149,
            "type": "arrowUp"
          },
          {
            "startTime": 27406,
            "type": "arrowRight"
          },
          {
            "startTime": 27408,
            "type": "arrowUp"
          },
          {
            "startTime": 27695,
            "type": "arrowRight"
          },
          {
            "startTime": 27699,
            "type": "arrowUp"
          },
          {
            "startTime": 27930,
            "type": "arrowRight"
          },
          {
            "startTime": 27956,
            "type": "arrowUp"
          },
          {
            "startTime": 28206,
            "type": "arrowRight"
          },
          {
            "startTime": 28210,
            "type": "arrowUp"
          },
          {
            "startTime": 28485,
            "type": "arrowRight"
          },
          {
            "startTime": 28493,
            "type": "arrowUp"
          },
          {
            "startTime": 28748,
            "type": "arrowUp"
          },
          {
            "startTime": 28764,
            "type": "arrowRight"
          },
          {
            "startTime": 29037,
            "type": "arrowUp"
          },
          {
            "startTime": 29053,
            "type": "arrowRight"
          },
          {
            "startTime": 29308,
            "type": "arrowRight"
          },
          {
            "startTime": 29311,
            "type": "arrowUp"
          },
          {
            "startTime": 29631,
            "type": "arrowRight"
          },
          {
            "startTime": 29639,
            "type": "arrowUp"
          },
          {
            "startTime": 29902,
            "type": "arrowUp"
          },
          {
            "startTime": 29951,
            "type": "arrowRight"
          },
          {
            "startTime": 30239,
            "type": "arrowUp"
          },
          {
            "startTime": 30269,
            "type": "arrowRight"
          },
          {
            "startTime": 30532,
            "type": "arrowUp"
          },
          {
            "startTime": 30540,
            "type": "arrowRight"
          },
          {
            "startTime": 30823,
            "type": "arrowRight"
          },
          {
            "startTime": 30827,
            "type": "arrowUp"
          },
          {
            "startTime": 31110,
            "type": "arrowRight"
          },
          {
            "startTime": 31113,
            "type": "arrowUp"
          },
          {
            "startTime": 31412,
            "type": "arrowRight"
          },
          {
            "startTime": 31416,
            "type": "arrowUp"
          },
          {
            "startTime": 31720,
            "type": "arrowUp"
          },
          {
            "startTime": 31742,
            "type": "arrowRight"
          },
          {
            "startTime": 32068,
            "type": "arrowUp"
          },
          {
            "startTime": 32092,
            "type": "arrowRight"
          },
          {
            "startTime": 32421,
            "type": "arrowUp"
          },
          {
            "startTime": 32429,
            "type": "arrowRight"
          },
          {
            "startTime": 32799,
            "type": "arrowRight"
          },
          {
            "startTime": 32803,
            "type": "arrowUp"
          },
          {
            "startTime": 33182,
            "type": "arrowUp"
          },
          {
            "startTime": 33216,
            "type": "arrowRight"
          },
          {
            "startTime": 33622,
            "type": "arrowUp"
          },
          {
            "startTime": 33645,
            "type": "arrowRight"
          },
          {
            "startTime": 34037,
            "type": "arrowRight"
          },
          {
            "startTime": 34086,
            "type": "arrowUp"
          },
          {
            "startTime": 34405,
            "type": "arrowRight"
          },
          {
            "startTime": 34414,
            "type": "arrowUp"
          },
          {
            "startTime": 34661,
            "type": "arrowRight"
          },
          {
            "startTime": 34935,
            "type": "arrowRight"
          },
          {
            "startTime": 35347,
            "type": "arrowUp"
          },
          {
            "startTime": 35367,
            "type": "arrowLeft"
          },
          {
            "startTime": 35629,
            "type": "arrowUp"
          },
          {
            "startTime": 35661,
            "type": "arrowLeft"
          },
          {
            "startTime": 35902,
            "type": "arrowUp"
          },
          {
            "startTime": 35917,
            "type": "arrowLeft"
          },
          {
            "startTime": 36102,
            "type": "arrowLeft"
          },
          {
            "startTime": 36143,
            "type": "arrowLeft"
          },
          {
            "startTime": 36176,
            "type": "arrowUp"
          },
          {
            "startTime": 36430,
            "type": "arrowLeft"
          },
          {
            "startTime": 36461,
            "type": "arrowUp"
          },
          {
            "startTime": 36718,
            "type": "arrowUp"
          },
          {
            "startTime": 36733,
            "type": "arrowLeft"
          },
          {
            "startTime": 36998,
            "type": "arrowLeft"
          },
          {
            "startTime": 37012,
            "type": "arrowUp"
          },
          {
            "startTime": 37261,
            "type": "arrowLeft"
          },
          {
            "startTime": 37287,
            "type": "arrowUp"
          },
          {
            "startTime": 37572,
            "type": "arrowUp"
          },
          {
            "startTime": 37575,
            "type": "arrowLeft"
          },
          {
            "startTime": 37855,
            "type": "arrowLeft"
          },
          {
            "startTime": 37862,
            "type": "arrowUp"
          },
          {
            "startTime": 38133,
            "type": "arrowUp"
          },
          {
            "startTime": 38157,
            "type": "arrowLeft"
          },
          {
            "startTime": 38463,
            "type": "arrowLeft"
          },
          {
            "startTime": 38472,
            "type": "arrowUp"
          },
          {
            "startTime": 38757,
            "type": "arrowUp"
          },
          {
            "startTime": 39005,
            "type": "arrowLeft"
          },
          {
            "startTime": 39037,
            "type": "arrowUp"
          },
          {
            "startTime": 39266,
            "type": "arrowLeft"
          },
          {
            "startTime": 39868,
            "type": "arrowUp"
          },
          {
            "startTime": 40118,
            "type": "arrowUp"
          },
          {
            "startTime": 40158,
            "type": "arrowLeft"
          },
          {
            "startTime": 40399,
            "type": "arrowLeft"
          },
          {
            "startTime": 40661,
            "type": "arrowLeft"
          },
          {
            "startTime": 40950,
            "type": "arrowUp"
          },
          {
            "startTime": 41231,
            "type": "arrowUp"
          },
          {
            "startTime": 41494,
            "type": "arrowLeft"
          },
          {
            "startTime": 41542,
            "type": "arrowUp"
          },
          {
            "startTime": 41765,
            "type": "arrowUp"
          },
          {
            "startTime": 42069,
            "type": "arrowUp"
          },
          {
            "startTime": 42357,
            "type": "arrowUp"
          },
          {
            "startTime": 42373,
            "type": "arrowLeft"
          },
          {
            "startTime": 42677,
            "type": "arrowUp"
          },
          {
            "startTime": 43277,
            "type": "arrowUp"
          },
          {
            "startTime": 43621,
            "type": "arrowUp"
          },
          {
            "startTime": 45471,
            "type": "arrowLeft"
          },
          {
            "startTime": 45853,
            "type": "arrowUp"
          },
          {
            "startTime": 45855,
            "type": "arrowLeft"
          },
          {
            "startTime": 46134,
            "type": "arrowUp"
          },
          {
            "startTime": 46150,
            "type": "arrowLeft"
          },
          {
            "startTime": 46437,
            "type": "arrowLeft"
          },
          {
            "startTime": 46452,
            "type": "arrowUp"
          },
          {
            "startTime": 46752,
            "type": "arrowUp"
          },
          {
            "startTime": 47021,
            "type": "arrowUp"
          },
          {
            "startTime": 47383,
            "type": "arrowUp"
          },
          {
            "startTime": 47421,
            "type": "arrowDown"
          },
          {
            "startTime": 47740,
            "type": "arrowDown"
          },
          {
            "startTime": 47743,
            "type": "arrowLeft"
          },
          {
            "startTime": 48053,
            "type": "arrowDown"
          },
          {
            "startTime": 48381,
            "type": "arrowLeft"
          },
          {
            "startTime": 48749,
            "type": "arrowUp"
          },
          {
            "startTime": 48982,
            "type": "arrowDown"
          },
          {
            "startTime": 49302,
            "type": "arrowDown"
          },
          {
            "startTime": 49317,
            "type": "arrowLeft"
          },
          {
            "startTime": 49645,
            "type": "arrowLeft"
          },
          {
            "startTime": 49960,
            "type": "arrowDown"
          },
          {
            "startTime": 50334,
            "type": "arrowLeft"
          },
          {
            "startTime": 50565,
            "type": "arrowDown"
          },
          {
            "startTime": 50889,
            "type": "arrowDown"
          },
          {
            "startTime": 51389,
            "type": "arrowDown"
          },
          {
            "startTime": 51653,
            "type": "arrowDown"
          },
          {
            "startTime": 51871,
            "type": "arrowLeft"
          },
          {
            "startTime": 51893,
            "type": "arrowLeft"
          },
          {
            "startTime": 52007,
            "type": "arrowDown"
          },
          {
            "startTime": 52286,
            "type": "arrowLeft"
          },
          {
            "startTime": 52404,
            "type": "arrowDown"
          },
          {
            "startTime": 52638,
            "type": "arrowLeft"
          },
          {
            "startTime": 52886,
            "type": "arrowDown"
          },
          {
            "startTime": 52889,
            "type": "arrowLeft"
          },
          {
            "startTime": 53157,
            "type": "arrowLeft"
          },
          {
            "startTime": 53429,
            "type": "arrowDown"
          },
          {
            "startTime": 53751,
            "type": "arrowLeft"
          },
          {
            "startTime": 54181,
            "type": "arrowRight"
          },
          {
            "startTime": 54358,
            "type": "arrowDown"
          },
          {
            "startTime": 54422,
            "type": "arrowRight"
          },
          {
            "startTime": 54446,
            "type": "arrowRight"
          },
          {
            "startTime": 54948,
            "type": "arrowDown"
          },
          {
            "startTime": 54964,
            "type": "arrowRight"
          },
          {
            "startTime": 55397,
            "type": "arrowDown"
          },
          {
            "startTime": 55452,
            "type": "arrowRight"
          },
          {
            "startTime": 55669,
            "type": "arrowDown"
          },
          {
            "startTime": 55981,
            "type": "arrowDown"
          },
          {
            "startTime": 56375,
            "type": "arrowRight"
          },
          {
            "startTime": 56662,
            "type": "arrowRight"
          },
          {
            "startTime": 56677,
            "type": "arrowLeft"
          },
          {
            "startTime": 56894,
            "type": "arrowRight"
          },
          {
            "startTime": 56901,
            "type": "arrowLeft"
          },
          {
            "startTime": 57166,
            "type": "arrowRight"
          },
          {
            "startTime": 57170,
            "type": "arrowLeft"
          },
          {
            "startTime": 57421,
            "type": "arrowRight"
          },
          {
            "startTime": 57425,
            "type": "arrowLeft"
          },
          {
            "startTime": 57709,
            "type": "arrowLeft"
          },
          {
            "startTime": 57743,
            "type": "arrowRight"
          },
          {
            "startTime": 58023,
            "type": "arrowLeft"
          },
          {
            "startTime": 58032,
            "type": "arrowRight"
          },
          {
            "startTime": 58309,
            "type": "arrowLeft"
          },
          {
            "startTime": 58325,
            "type": "arrowRight"
          },
          {
            "startTime": 58640,
            "type": "arrowRight"
          },
          {
            "startTime": 59005,
            "type": "arrowLeft"
          },
          {
            "startTime": 59014,
            "type": "arrowRight"
          },
          {
            "startTime": 59445,
            "type": "arrowUp"
          },
          {
            "startTime": 59477,
            "type": "arrowDown"
          },
          {
            "startTime": 59798,
            "type": "arrowDown"
          },
          {
            "startTime": 60006,
            "type": "arrowDown"
          },
          {
            "startTime": 60006,
            "type": "arrowUp"
          },
          {
            "startTime": 60006,
            "type": "arrowUp"
          },
          {
            "startTime": 60006,
            "type": "arrowDown"
          },
          {
            "startTime": 60006,
            "type": "arrowUp"
          },
          {
            "startTime": 60006,
            "type": "arrowDown"
          },
          {
            "startTime": 60006,
            "type": "arrowDown"
          },
          {
            "startTime": 60006,
            "type": "arrowUp"
          }
    ]
};

export default config;