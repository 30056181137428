import avec from './avec';
import jegvarazs from './jegvarazs';
import vasorrubaba from './vasorrubaba';
import tigris from './tigris';

const config = {
    songs: {
        avec,
        jegvarazs,
        vasorrubaba,
        tigris,
    }
};

export default config;
