const config = {
    title: 'Avec',
    videoId: 'l9-LxYvQSLM',
    start: 0,
    end: 60,
    arrows: [
        {
            "startTime": 480,
            "type": "arrowLeft"
        },
        {
            "startTime": 2218,
            "type": "arrowRight"
        },
        {
            "startTime": 3867,
            "type": "arrowUp"
        },
        {
            "startTime": 5665,
            "type": "arrowDown"
        },
        {
            "startTime": 7820,
            "type": "arrowLeft"
        },
        {
            "startTime": 9202,
            "type": "arrowRight"
        },
        {
            "startTime": 11001,
            "type": "arrowUp"
        },
        {
            "startTime": 12864,
            "type": "arrowDown"
        },
        {
            "startTime": 15001,
            "type": "arrowLeft"
        },
        {
            "startTime": 15024,
            "type": "arrowRight"
        },
        {
            "startTime": 16657,
            "type": "arrowUp"
        },
        {
            "startTime": 18433,
            "type": "arrowLeft"
        },
        {
            "startTime": 20554,
            "type": "arrowRight"
        },
        {
            "startTime": 22448,
            "type": "arrowUp"
        },
        {
            "startTime": 24056,
            "type": "arrowLeft"
        },
        {
            "startTime": 25935,
            "type": "arrowRight"
        },
        {
            "startTime": 27825,
            "type": "arrowRight"
        },
        {
            "startTime": 29867,
            "type": "arrowLeft"
        },
        {
            "startTime": 31482,
            "type": "arrowLeft"
        },
        {
            "startTime": 33401,
            "type": "arrowUp"
        },
        {
            "startTime": 35698,
            "type": "arrowDown"
        },
        {
            "startTime": 39352,
            "type": "arrowUp"
        },
        {
            "startTime": 41059,
            "type": "arrowRight"
        },
        {
            "startTime": 41064,
            "type": "arrowLeft"
        },
        {
            "startTime": 41565,
            "type": "arrowLeft"
        },
        {
            "startTime": 42658,
            "type": "arrowUp"
        },
        {
            "startTime": 44635,
            "type": "arrowDown"
        },
        {
            "startTime": 44656,
            "type": "arrowUp"
        },
        {
            "startTime": 46604,
            "type": "arrowLeft"
        },
        {
            "startTime": 48353,
            "type": "arrowLeft"
        },
        {
            "startTime": 49832,
            "type": "arrowRight"
        },
        {
            "startTime": 51297,
            "type": "arrowRight"
        },
        {
            "startTime": 52215,
            "type": "arrowUp"
        },
        {
            "startTime": 54017,
            "type": "arrowDown"
        },
        {
            "startTime": 55952,
            "type": "arrowLeft"
        },
        {
            "startTime": 57819,
            "type": "arrowRight"
        },
        {
            "startTime": 59264,
            "type": "arrowUp"
        }
    ]
};

export default config;