import React, { Component, Fragment } from 'react';
import YouTube from 'react-youtube';
import classnames from 'classnames';
import config from './config';
import Svg from './svg';
import SongSelector from './SongSelector';
import './App.css';
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.offset = 400;
    this.index = -1;
    this.state = this._getInitialProps();
    this.generationMode = true;
    this.generatedConfig = [];
  }

  componentDidMount = () => {
    document.addEventListener('keydown', (event) => {
      const keyName = event.key;

      if (this.generationMode && this.state.selectedSongId) {
        if (keyName === 'a') {
          console.log(JSON.stringify(this.generatedConfig, null, 2));
        }
        else {
          this.generatedConfig.push({ startTime: parseInt(this.ytPlayer.getCurrentTime() * 1000, 10), type: keyName });
        }
      }

      if (keyName === 'Escape') {
        clearInterval(this.interval);
        this.setState(this._getInitialProps());
      }

      if (
        keyName === 'ArrowLeft' ||
        keyName === 'ArrowUp' ||
        keyName === 'ArrowDown' ||
        keyName === 'ArrowRight'
      ) {
        const pressedKeys = {...this.state.pressedKeys};
        pressedKeys[keyName] = true;

        this.setState(state => ({
          ...state,
          pressedKeys
        }));
      }

      const elementIndex = this.state.arrows.findIndex(item => item.type.toLowerCase() === keyName.toLowerCase() && !item.get);
      
      if (elementIndex > -1) {
        let arrows = this.state.arrows.slice();
        arrows[elementIndex].get = true;

        this.setState(state => ({
          ...state,
          points: state.points + 1,
          arrows,
        }));
      }
    });

    document.addEventListener('keyup', (event) => {
      const keyName = event.key;

      if (
        keyName === 'ArrowLeft' ||
        keyName === 'ArrowUp' ||
        keyName === 'ArrowDown' ||
        keyName === 'ArrowRight'
      ) {
        const pressedKeys = {...this.state.pressedKeys};
        pressedKeys[keyName] = false;

        this.setState(state => ({
          ...state,
          pressedKeys
        }));
      }
    });


  };

  _getInitialProps = () => ({
    arrows: [],
    points: 0,
    selectedSongId: undefined,
    pressedKeys: {
      ArrowLeft: false,
      ArrowUp: false,
      ArrowDown: false,
      ArrorRight: false,
    },
  });

  _getArrows = (arrows, currentTime, index) => {
    const newArrows = [];

    for (let i = index; i < arrows.length; i++) {
      const startTime = arrows[i].startTime;
      const startTimeEnd = startTime + this.offset;

      if (currentTime >= startTime && currentTime <= startTimeEnd) {
        newArrows.push(arrows[i]);
        this.index = i;
      }
    }

    return newArrows;
  }

  _removeExpiredArrows = (arrows, currentTime) => {
    return arrows.map(item => {
      const arrow = {...item};
      const startTimeEnd = item.startTime + 4200;
      
      if (startTimeEnd < currentTime) {
        arrow.get = true;
      }

      return arrow;
    });
  }

  _onReady = ({ target }) => {
    this.interval = setInterval(() => {
      this.ytPlayer = target;
      const currentTime = target.getCurrentTime() * 1000;

      const newArrows = this._getArrows(config.songs[this.state.selectedSongId].arrows, currentTime, this.index + 1);

      if (newArrows.length > 0) {
        this.setState((state) => ({
          ...state,
          arrows: [...state.arrows, ...newArrows],
        }));
      }

      this.setState((state) => ({
        ...state,
        arrows: this._removeExpiredArrows(state.arrows, currentTime)
      }));
    }, 300);
  }

  _onStateChange = ({ target, data }) => {
 
  }

  _handleSongSelection = (songId) => {
    this.setState({ selectedSongId: songId });
  }

  render() {
    const game = () => {
      const opts = {
        height: '197',
        width: '350',
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          controls: 0,
          showinfo: 0,
          rel: 0,
          disablekb: 1,
          autohide: 1,
          modestbranding: 0,
          enablejsapi: 0,
          iv_load_policy: 3,
          start: config.songs[this.state.selectedSongId].start,
          end: config.songs[this.state.selectedSongId].end,
        }
      };

      return (
        <Fragment>
          <div className="points">{this.state.points} / {config.songs[this.state.selectedSongId].arrows.length}</div>
          <YouTube
            videoId={config.songs[this.state.selectedSongId].videoId}
            opts={opts}
            onReady={this._onReady}
            onStateChange={this._onStateChange}
            className="video"
          />
          <div className="control-arrows">
            <svg
              className={
                classnames('arrow', `arrow--arrowLeft`, {
                  'arrow--show': this.state.pressedKeys.ArrowLeft
                })
              }
              style={{ filter: 'url(#green-dropshadow)' }}
            >
              <use xlinkHref={`#arrowLeft`} />
            </svg>
            <svg
              className={
                classnames('arrow', `arrow--arrowUp`, {
                  'arrow--show': this.state.pressedKeys.ArrowUp
                })
              }
              style={{ filter: 'url(#green-dropshadow)' }}
            >
              <use xlinkHref={`#arrowUp`} />
            </svg>
            <svg
              className={
                classnames('arrow', `arrow--arrowDown`, {
                  'arrow--show': this.state.pressedKeys.ArrowDown
                })
              }
              style={{ filter: 'url(#green-dropshadow)' }}
            >
              <use xlinkHref={`#arrowDown`} />
            </svg>
            <svg
              className={
                classnames('arrow', `arrow--arrowRight`, {
                  'arrow--show': this.state.pressedKeys.ArrowRight
                })
              }
              style={{ filter: 'url(#green-dropshadow)' }}
            >
              <use xlinkHref={`#arrowRight`} />
            </svg>
          </div>
          <div className="arrows">
            {
              this.state.arrows.map((item, index) => (
                <svg
                  className={
                    classnames('arrow arrow--has-anim', `arrow--${item.type}`, {
                      'arrow--get': item.get
                    })
                  }
                  key={index}
                  style={{ filter: 'url(#dropshadow)' }}
                >
                  <use xlinkHref={`#${item.type}`} />
                </svg>
              ))
            }
          </div>
        </Fragment>
      );
    };

    return (
      <div className="App">
        <Svg />
        {
          !this.state.selectedSongId &&
          <SongSelector
            songs={config.songs}
            onSubmit={this._handleSongSelection}
          />
        }
        {
          this.state.selectedSongId &&
          game()
        }
      </div>
    );
  }
}

export default App;
