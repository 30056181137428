const config = {
    title: 'Gryllus Vilmos: Maszkabál - Banya',
    videoId: 'tSHEhPsEFRo',
    start: 0,
    end: 60,
    arrows: [
    {
        "startTime": 1304,
        "type": "arrowLeft"
        },
        {
        "startTime": 8249,
        "type": "arrowLeft"
        },
        {
        "startTime": 9480,
        "type": "arrowUp"
        },
        {
        "startTime": 10297,
        "type": "arrowRight"
        },
        {
        "startTime": 10639,
        "type": "arrowRight"
        },
        {
        "startTime": 11017,
        "type": "arrowUp"
        },
        {
        "startTime": 11032,
        "type": "arrowUp"
        },
        {
        "startTime": 11600,
        "type": "arrowRight"
        },
        {
        "startTime": 11896,
        "type": "arrowRight"
        },
        {
        "startTime": 12200,
        "type": "arrowLeft"
        },
        {
        "startTime": 12210,
        "type": "arrowRight"
        },
        {
        "startTime": 12472,
        "type": "arrowRight"
        },
        {
        "startTime": 12487,
        "type": "arrowLeft"
        },
        {
        "startTime": 12760,
        "type": "arrowRight"
        },
        {
        "startTime": 13006,
        "type": "arrowRight"
        },
        {
        "startTime": 13013,
        "type": "arrowLeft"
        },
        {
        "startTime": 13264,
        "type": "arrowLeft"
        },
        {
        "startTime": 13275,
        "type": "arrowRight"
        },
        {
        "startTime": 13528,
        "type": "arrowLeft"
        },
        {
        "startTime": 13537,
        "type": "arrowRight"
        },
        {
        "startTime": 13801,
        "type": "arrowRight"
        },
        {
        "startTime": 14017,
        "type": "arrowRight"
        },
        {
        "startTime": 14448,
        "type": "arrowDown"
        },
        {
        "startTime": 14817,
        "type": "arrowUp"
        },
        {
        "startTime": 14833,
        "type": "arrowDown"
        },
        {
        "startTime": 15103,
        "type": "arrowDown"
        },
        {
        "startTime": 15151,
        "type": "arrowUp"
        },
        {
        "startTime": 15456,
        "type": "arrowUp"
        },
        {
        "startTime": 15768,
        "type": "arrowUp"
        },
        {
        "startTime": 15771,
        "type": "arrowDown"
        },
        {
        "startTime": 16528,
        "type": "arrowUp"
        },
        {
        "startTime": 17216,
        "type": "arrowUp"
        },
        {
        "startTime": 18449,
        "type": "arrowUp"
        },
        {
        "startTime": 18753,
        "type": "arrowUp"
        },
        {
        "startTime": 19136,
        "type": "arrowUp"
        },
        {
        "startTime": 19496,
        "type": "arrowUp"
        },
        {
        "startTime": 19504,
        "type": "arrowDown"
        },
        {
        "startTime": 19833,
        "type": "arrowDown"
        },
        {
        "startTime": 19848,
        "type": "arrowUp"
        },
        {
        "startTime": 20184,
        "type": "arrowUp"
        },
        {
        "startTime": 20496,
        "type": "arrowUp"
        },
        {
        "startTime": 20888,
        "type": "arrowLeft"
        },
        {
        "startTime": 20912,
        "type": "arrowRight"
        },
        {
        "startTime": 21232,
        "type": "arrowRight"
        },
        {
        "startTime": 21240,
        "type": "arrowLeft"
        },
        {
        "startTime": 21530,
        "type": "arrowRight"
        },
        {
        "startTime": 21555,
        "type": "arrowLeft"
        },
        {
        "startTime": 21793,
        "type": "arrowRight"
        },
        {
        "startTime": 21817,
        "type": "arrowLeft"
        },
        {
        "startTime": 22088,
        "type": "arrowRight"
        },
        {
        "startTime": 22091,
        "type": "arrowLeft"
        },
        {
        "startTime": 22424,
        "type": "arrowLeft"
        },
        {
        "startTime": 22432,
        "type": "arrowRight"
        },
        {
        "startTime": 22785,
        "type": "arrowRight"
        },
        {
        "startTime": 22800,
        "type": "arrowLeft"
        },
        {
        "startTime": 23121,
        "type": "arrowRight"
        },
        {
        "startTime": 23124,
        "type": "arrowLeft"
        },
        {
        "startTime": 23448,
        "type": "arrowRight"
        },
        {
        "startTime": 23848,
        "type": "arrowUp"
        },
        {
        "startTime": 24897,
        "type": "arrowRight"
        },
        {
        "startTime": 25811,
        "type": "arrowLeft"
        },
        {
        "startTime": 26369,
        "type": "arrowUp"
        },
        {
        "startTime": 26529,
        "type": "arrowUp"
        },
        {
        "startTime": 26944,
        "type": "arrowRight"
        },
        {
        "startTime": 27265,
        "type": "arrowRight"
        },
        {
        "startTime": 27641,
        "type": "arrowUp"
        },
        {
        "startTime": 28265,
        "type": "arrowUp"
        },
        {
        "startTime": 28842,
        "type": "arrowUp"
        },
        {
        "startTime": 29202,
        "type": "arrowRight"
        },
        {
        "startTime": 29553,
        "type": "arrowUp"
        },
        {
        "startTime": 29711,
        "type": "arrowUp"
        },
        {
        "startTime": 30528,
        "type": "arrowRight"
        },
        {
        "startTime": 31114,
        "type": "arrowUp"
        },
        {
        "startTime": 31752,
        "type": "arrowRight"
        },
        {
        "startTime": 31952,
        "type": "arrowRight"
        },
        {
        "startTime": 32015,
        "type": "arrowUp"
        },
        {
        "startTime": 32192,
        "type": "arrowRight"
        },
        {
        "startTime": 32815,
        "type": "arrowRight"
        },
        {
        "startTime": 33010,
        "type": "arrowUp"
        },
        {
        "startTime": 33240,
        "type": "arrowRight"
        },
        {
        "startTime": 33577,
        "type": "arrowUp"
        },
        {
        "startTime": 33783,
        "type": "arrowRight"
        },
        {
        "startTime": 33815,
        "type": "arrowUp"
        },
        {
        "startTime": 34000,
        "type": "arrowRight"
        },
        {
        "startTime": 34096,
        "type": "arrowUp"
        },
        {
        "startTime": 34304,
        "type": "arrowRight"
        },
        {
        "startTime": 34321,
        "type": "arrowUp"
        },
        {
        "startTime": 34599,
        "type": "arrowRight"
        },
        {
        "startTime": 34873,
        "type": "arrowRight"
        },
        {
        "startTime": 34895,
        "type": "arrowUp"
        },
        {
        "startTime": 35144,
        "type": "arrowUp"
        },
        {
        "startTime": 35440,
        "type": "arrowUp"
        },
        {
        "startTime": 35528,
        "type": "arrowDown"
        },
        {
        "startTime": 35831,
        "type": "arrowDown"
        },
        {
        "startTime": 35855,
        "type": "arrowRight"
        },
        {
        "startTime": 36119,
        "type": "arrowDown"
        },
        {
        "startTime": 36127,
        "type": "arrowRight"
        },
        {
        "startTime": 36337,
        "type": "arrowDown"
        },
        {
        "startTime": 36403,
        "type": "arrowRight"
        },
        {
        "startTime": 36418,
        "type": "arrowDown"
        },
        {
        "startTime": 36618,
        "type": "arrowDown"
        },
        {
        "startTime": 36634,
        "type": "arrowRight"
        },
        {
        "startTime": 36802,
        "type": "arrowDown"
        },
        {
        "startTime": 36834,
        "type": "arrowRight"
        },
        {
        "startTime": 37024,
        "type": "arrowDown"
        },
        {
        "startTime": 37040,
        "type": "arrowRight"
        },
        {
        "startTime": 37297,
        "type": "arrowRight"
        },
        {
        "startTime": 37306,
        "type": "arrowDown"
        },
        {
        "startTime": 37552,
        "type": "arrowDown"
        },
        {
        "startTime": 37785,
        "type": "arrowRight"
        },
        {
        "startTime": 38034,
        "type": "arrowDown"
        },
        {
        "startTime": 38041,
        "type": "arrowRight"
        },
        {
        "startTime": 38314,
        "type": "arrowDown"
        },
        {
        "startTime": 38576,
        "type": "arrowDown"
        },
        {
        "startTime": 38889,
        "type": "arrowDown"
        },
        {
        "startTime": 39257,
        "type": "arrowRight"
        },
        {
        "startTime": 39297,
        "type": "arrowLeft"
        },
        {
        "startTime": 39664,
        "type": "arrowRight"
        },
        {
        "startTime": 39672,
        "type": "arrowLeft"
        },
        {
        "startTime": 39904,
        "type": "arrowRight"
        },
        {
        "startTime": 39907,
        "type": "arrowLeft"
        },
        {
        "startTime": 40128,
        "type": "arrowLeft"
        },
        {
        "startTime": 40162,
        "type": "arrowLeft"
        },
        {
        "startTime": 40184,
        "type": "arrowRight"
        },
        {
        "startTime": 40889,
        "type": "arrowUp"
        },
        {
        "startTime": 41135,
        "type": "arrowUp"
        },
        {
        "startTime": 41448,
        "type": "arrowUp"
        },
        {
        "startTime": 41474,
        "type": "arrowLeft"
        },
        {
        "startTime": 41759,
        "type": "arrowUp"
        },
        {
        "startTime": 42167,
        "type": "arrowLeft"
        },
        {
        "startTime": 42407,
        "type": "arrowUp"
        },
        {
        "startTime": 42672,
        "type": "arrowUp"
        },
        {
        "startTime": 42697,
        "type": "arrowUp"
        },
        {
        "startTime": 42977,
        "type": "arrowUp"
        },
        {
        "startTime": 43353,
        "type": "arrowLeft"
        },
        {
        "startTime": 43417,
        "type": "arrowDown"
        },
        {
        "startTime": 43696,
        "type": "arrowLeft"
        },
        {
        "startTime": 43785,
        "type": "arrowDown"
        },
        {
        "startTime": 44321,
        "type": "arrowDown"
        },
        {
        "startTime": 45681,
        "type": "arrowUp"
        },
        {
        "startTime": 46025,
        "type": "arrowUp"
        },
        {
        "startTime": 46347,
        "type": "arrowUp"
        },
        {
        "startTime": 46609,
        "type": "arrowUp"
        },
        {
        "startTime": 46952,
        "type": "arrowUp"
        },
        {
        "startTime": 46968,
        "type": "arrowDown"
        },
        {
        "startTime": 47256,
        "type": "arrowUp"
        },
        {
        "startTime": 47289,
        "type": "arrowDown"
        },
        {
        "startTime": 47527,
        "type": "arrowDown"
        },
        {
        "startTime": 47551,
        "type": "arrowUp"
        },
        {
        "startTime": 47840,
        "type": "arrowUp"
        },
        {
        "startTime": 47876,
        "type": "arrowLeft"
        },
        {
        "startTime": 48159,
        "type": "arrowLeft"
        },
        {
        "startTime": 48824,
        "type": "arrowRight"
        },
        {
        "startTime": 49046,
        "type": "arrowRight"
        },
        {
        "startTime": 49391,
        "type": "arrowRight"
        },
        {
        "startTime": 49703,
        "type": "arrowRight"
        },
        {
        "startTime": 50048,
        "type": "arrowUp"
        },
        {
        "startTime": 50633,
        "type": "arrowUp"
        },
        {
        "startTime": 50936,
        "type": "arrowUp"
        },
        {
        "startTime": 50977,
        "type": "arrowDown"
        },
        {
        "startTime": 51137,
        "type": "arrowUp"
        },
        {
        "startTime": 51584,
        "type": "arrowUp"
        },
        {
        "startTime": 51850,
        "type": "arrowUp"
        },
        {
        "startTime": 52242,
        "type": "arrowLeft"
        },
        {
        "startTime": 52449,
        "type": "arrowUp"
        },
        {
        "startTime": 52762,
        "type": "arrowUp"
        },
        {
        "startTime": 53064,
        "type": "arrowUp"
        },
        {
        "startTime": 53793,
        "type": "arrowUp"
        },
        {
        "startTime": 54104,
        "type": "arrowUp"
        },
        {
        "startTime": 54489,
        "type": "arrowLeft"
        },
        {
        "startTime": 54704,
        "type": "arrowLeft"
        },
        {
        "startTime": 54759,
        "type": "arrowLeft"
        },
        {
        "startTime": 55087,
        "type": "arrowUp"
        },
        {
        "startTime": 55259,
        "type": "arrowUp"
        },
        {
        "startTime": 55712,
        "type": "arrowUp"
        },
        {
        "startTime": 56073,
        "type": "arrowRight"
        },
        {
        "startTime": 56392,
        "type": "arrowLeft"
        },
        {
        "startTime": 56400,
        "type": "arrowRight"
        },
        {
        "startTime": 57055,
        "type": "arrowRight"
        },
        {
        "startTime": 57249,
        "type": "arrowRight"
        },
        {
        "startTime": 57345,
        "type": "arrowRight"
        },
        {
        "startTime": 58209,
        "type": "arrowRight"
        },
        {
        "startTime": 58234,
        "type": "arrowRight"
        },
        {
        "startTime": 58552,
        "type": "arrowRight"
        },
        {
        "startTime": 58593,
        "type": "arrowUp"
        },
        {
        "startTime": 58888,
        "type": "arrowUp"
        },
        {
        "startTime": 58904,
        "type": "arrowRight"
        },
        {
        "startTime": 59209,
        "type": "arrowRight"
        },
        {
        "startTime": 59216,
        "type": "arrowUp"
        },
        {
        "startTime": 59535,
        "type": "arrowRight"
        },
        {
        "startTime": 59552,
        "type": "arrowUp"
        },
        {
        "startTime": 59879,
        "type": "arrowRight"
        },
        {
        "startTime": 59898,
        "type": "arrowLeft"
        },
        {
        "startTime": 60007,
        "type": "arrowUp"
        },
        {
        "startTime": 60007,
        "type": "arrowRight"
        },
        {
        "startTime": 60007,
        "type": "arrowRight"
        },
        {
        "startTime": 60007,
        "type": "arrowRight"
        },
        {
        "startTime": 60007,
        "type": "arrowRight"
        },
        {
        "startTime": 60007,
        "type": "arrowRight"
        },
        {
        "startTime": 60007,
        "type": "arrowUp"
        }
    ]
};

export default config;