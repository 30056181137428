import React from 'react';

const svg = () => (
    <svg
    aria-hidden="true"
    style={{
      position: 'absolute',
      width: 0,
      height: 0,
      overflow: 'hidden'
    }}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
        <defs>
            <filter id="dropshadow" height="130%">
                <feFlood result="flood" floodColor="#f6f589" floodOpacity="1"></feFlood>
                <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"></feComposite>
                <feMorphology in="mask" result="dilated" operator="dilate" radius="1"></feMorphology>
                <feGaussianBlur in="dilated" result="blurred" stdDeviation="5"></feGaussianBlur>
                <feMerge>
                    <feMergeNode in="blurred"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
            <filter id="green-dropshadow" height="130%">
                <feFlood result="flood" floodColor="#d8439e" floodOpacity="1"></feFlood>
                <feComposite in="flood" result="mask" in2="SourceGraphic" operator="in"></feComposite>
                <feMorphology in="mask" result="dilated" operator="dilate" radius="1"></feMorphology>
                <feGaussianBlur in="dilated" result="blurred" stdDeviation="5"></feGaussianBlur>
                <feMerge>
                    <feMergeNode in="blurred"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
            <symbol id="icon-award" viewBox="0 0 32 32">
                <title>award</title>
                <path d="M10 16.708v0 0c-1.841-1.648-3-4.043-3-6.708 0-4.971 4.029-9 9-9s9 4.029 9 9c0 2.665-1.159 5.060-3 6.708v14.292l-6-6-6 6v-14.292zM11 17.484v11.116l5-5 5 5v-11.116c-1.43 0.957-3.15 1.516-5 1.516s-3.57-0.558-5-1.516v0 0zM16 18c4.418 0 8-3.582 8-8s-3.582-8-8-8c-4.418 0-8 3.582-8 8s3.582 8 8 8v0zM16 16v0c-3.314 0-6-2.686-6-6s2.686-6 6-6c3.314 0 6 2.686 6 6s-2.686 6-6 6zM16 15c2.761 0 5-2.239 5-5s-2.239-5-5-5c-2.761 0-5 2.239-5 5s2.239 5 5 5v0z"></path>
            </symbol>
            <symbol id="icon-arrow-right" viewBox="0 0 32 32">
                <title>arrow-right</title>
                <path d="M0 20h16l-6 6 6 6 16-16-16-16-6 6 6 6h-16z"></path>
            </symbol>
            <symbol id="icon-arrow-left" viewBox="0 0 32 32">
                <title>arrow-left</title>
                <path d="M32 12h-16l6-6-6-6-16 16 16 16 6-6-6-6h16z"></path>
            </symbol>
            <symbol id="icon-arrow-down" viewBox="0 0 32 32">
                <title>arrow-down</title>
                <path d="M12 0v16l-6-6-6 6 16 16 16-16-6-6-6 6v-16z"></path>
            </symbol>
            <symbol id="icon-arrow-up" viewBox="0 0 32 32">
                <title>arrow-up</title>
                <path d="M20 32v-16l6 6 6-6-16-16-16 16 6 6 6-6v16z"></path>
            </symbol>
            <symbol id="arrowRight" viewBox="0 0 23 28">
                <title>arrow-right1</title>
                <path d="M23 15c0 0.531-0.203 1.047-0.578 1.422l-10.172 10.172c-0.375 0.359-0.891 0.578-1.422 0.578s-1.031-0.219-1.406-0.578l-1.172-1.172c-0.375-0.375-0.594-0.891-0.594-1.422s0.219-1.047 0.594-1.422l4.578-4.578h-11c-1.125 0-1.828-0.938-1.828-2v-2c0-1.062 0.703-2 1.828-2h11l-4.578-4.594c-0.375-0.359-0.594-0.875-0.594-1.406s0.219-1.047 0.594-1.406l1.172-1.172c0.375-0.375 0.875-0.594 1.406-0.594s1.047 0.219 1.422 0.594l10.172 10.172c0.375 0.359 0.578 0.875 0.578 1.406z"></path>
            </symbol>
            <symbol id="arrowLeft" viewBox="0 0 25 28">
                <title>arrow-left1</title>
                <path d="M24 14v2c0 1.062-0.703 2-1.828 2h-11l4.578 4.594c0.375 0.359 0.594 0.875 0.594 1.406s-0.219 1.047-0.594 1.406l-1.172 1.188c-0.359 0.359-0.875 0.578-1.406 0.578s-1.047-0.219-1.422-0.578l-10.172-10.187c-0.359-0.359-0.578-0.875-0.578-1.406s0.219-1.047 0.578-1.422l10.172-10.156c0.375-0.375 0.891-0.594 1.422-0.594s1.031 0.219 1.406 0.594l1.172 1.156c0.375 0.375 0.594 0.891 0.594 1.422s-0.219 1.047-0.594 1.422l-4.578 4.578h11c1.125 0 1.828 0.938 1.828 2z"></path>
            </symbol>
            <symbol id="arrowDown" viewBox="0 0 26 28">
                <title>arrow-down1</title>
                <path d="M25.172 13c0 0.531-0.219 1.047-0.578 1.406l-10.172 10.187c-0.375 0.359-0.891 0.578-1.422 0.578s-1.047-0.219-1.406-0.578l-10.172-10.187c-0.375-0.359-0.594-0.875-0.594-1.406s0.219-1.047 0.594-1.422l1.156-1.172c0.375-0.359 0.891-0.578 1.422-0.578s1.047 0.219 1.406 0.578l4.594 4.594v-11c0-1.094 0.906-2 2-2h2c1.094 0 2 0.906 2 2v11l4.594-4.594c0.359-0.359 0.875-0.578 1.406-0.578s1.047 0.219 1.422 0.578l1.172 1.172c0.359 0.375 0.578 0.891 0.578 1.422z"></path>
            </symbol>
            <symbol id="arrowUp" viewBox="0 0 26 28">
                <title>arrow-up1</title>
                <path d="M25.172 15.172c0 0.531-0.219 1.031-0.578 1.406l-1.172 1.172c-0.375 0.375-0.891 0.594-1.422 0.594s-1.047-0.219-1.406-0.594l-4.594-4.578v11c0 1.125-0.938 1.828-2 1.828h-2c-1.062 0-2-0.703-2-1.828v-11l-4.594 4.578c-0.359 0.375-0.875 0.594-1.406 0.594s-1.047-0.219-1.406-0.594l-1.172-1.172c-0.375-0.375-0.594-0.875-0.594-1.406s0.219-1.047 0.594-1.422l10.172-10.172c0.359-0.375 0.875-0.578 1.406-0.578s1.047 0.203 1.422 0.578l10.172 10.172c0.359 0.375 0.578 0.891 0.578 1.422z"></path>
            </symbol>
        </defs>
    </svg>
);

export default svg;