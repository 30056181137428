import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SongSelector extends Component {
    constructor(props) {
        super(props);
        this.radioRef = React.createRef();
        this.state = {
            songId: Object.entries(this.props.songs)[0][0],
        }
    }

    componentDidMount = () => {
        this.radioRef.current.focus();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit(this.state.songId);
    }

    handleOnChange = (event) => {
        this.setState({ songId: event.target.value });
    }

    render() {
        const { songs } = this.props;

        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                {
                    Object.entries(songs).map((item, index) => (
                        <label key={item[0]}>
                            <input
                                type="radio"
                                name="song"
                                value={item[0]}
                                checked={this.state.songId === item[0]}
                                onChange={this.handleOnChange}
                                ref={this.state.songId === item[0] ? this.radioRef : undefined}
                            />
                            {item[1].title}
                        </label>
                    ))
                }
                <button type="submit" style={{ display: 'none' }}>ok</button>
                </form>
            </div>
        );
    };
};

SongSelector.propTypes = {
    songs: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default SongSelector;